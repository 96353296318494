<template>
  <div>
    <nav-header @mediachangedata="mediahandler"></nav-header>
    <div class="envCon activity-container" :class="lang">
      <div class="activity">
        <div class="news feature">
          <h4 >{{$t('activity.title')}}</h4>
          <ul>
            <li v-for="(item,index) in zh_data.newLists" :key="index" @click="toDetail(index)">
            <!-- 判断封面是否有 -->
              <img  v-lazy="item.bannerList.length>0 ? item.bannerList[0].url : ''" alt="">
              <div class="title-container">
                <span>{{item.title | ellipsis}}</span>
                <p>{{item.date}}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import NavHeader from '../components/Header'
import {mapState} from 'vuex'
import { getdataInfo } from "../api/home";
export default {
  components:{
    NavHeader
  },
  data() {
    return{
      id:7,
      pageType: 'activity',

      videoList:[
        {
          url:'https://v.qq.com/x/page/f3250nzg8o8.html',
          imgurl:'/img/media/video1.JPG',
          title:'潘石屹：希望每个孩子都成为人格高贵的人',
          date:'2021/6/3'
        },
        {
          url:'https://v.qq.com/x/page/r30493qzayh.html',
          imgurl:'/img/media/video2.jpg',
          title:'天水养正幼儿园建成啦！',
          date:' 2020/1/10'
        },
        {
          url:'https://v.qq.com/x/page/s3259er7wzv.html',
          imgurl:'/img/media/video3.jpg',
          title:'潘石屹：让正义护佑我们',
          date:'2020/1/9'
        }
      ],
      envideoList:[
        {
          url:'https://v.qq.com/x/page/f3250nzg8o8.html',
          imgurl:'/img/media/video1.JPG',
          title:'Pan Shiyi: From a Child to a Man, Hope a Noble Character Burgeons and Thrives',
          date:'June 2021'
        },
        {
          url:'https://v.qq.com/x/page/r30493qzayh.html',
          imgurl:'/img/media/video2.jpg',
          title:'Tian Shui Yang Zheng Kindergarten Is Completed!',
          date:' January 2020'
        },
        {
          url:'https://v.qq.com/x/page/s3259er7wzv.html',
          imgurl:'/img/media/video3.jpg',
          title:'Pan Shiyi: May Righteousness Shield Us',
          date:'January 2020'
        }
      ],
      zh_data:{},
    }
  },
  filters:{
    ellipsis(value){
      if(value.length>50){
        return value.slice(0,50) + '...'
      }
      return  value
    }
  },
  mounted() {
    const staggeringOption = {
      delay: 300,
      distance: "50px",
      duration: 500,
      easing: "ease-in-out",
      origin: "bottom",
      date:''
    };

    this.$scroll().reveal(".feature", { ...staggeringOption, interval: 350 });
    this.getdata()
  },
  computed:{
    ...mapState({
      lang:state =>state.lang
    })
  },
  //query: {content: JSON.stringify(this.zh_data.newLists[index])} // 参数传值
  methods: {
    toDetail (index) {
      this.$router.push({
        name: 'activityDetail',
        path:'/activityDetail',
        query:{id:index}
      })
    },

    async getdata() {
      const data = await getdataInfo({ id: this.id, pageType: this.pageType })
      const zh_envdata= JSON.parse(data.esgContent.cnContent)
      // zh_envdata.newLists = zh_envdata.newLists.reverse()
      // console.log( zh_envdata.newLists)
      zh_envdata.newLists.sort(function(a, b) {
        return b.date < a.date ? -1 : 1
      })
      const en_envdata= JSON.parse(data.esgContent.enContent)

      en_envdata.newLists.sort(function(a, b) {
        return b.date < a.date ? -1 : 1
      })
      if(this.$i18n.locale=='zh'){
        this.zh_data = zh_envdata ;
        // 中文数据
        // this.zh_data = zh_envdata
      }else {
        this.zh_data = en_envdata
        //this.zh_data = zh_envdata
      }
    },

    mediahandler(){
      this.getdata()
    }
  }
}
</script>
<style>
.wscnph{
  display: block;
  width: 100%;
}
</style>
<style scoped lang="scss">
.activity-container{
  width: 100%;
  background: #F5F5F5;
}
.activity{
  width: 1086px;
  padding: 80px 0;
  margin: 0 auto;
}

.activity h4{
  font-size: 36px;
  color:rgb(34, 195, 173);
  font-weight: bold;
  padding-bottom: 30px;
}
.activity img{
  width: 100%;
  height: 220px;
}

.activity span{
  display: block;
  padding-top: 20px;
  color: #353535;
  font-size: 18px;
}
.en .activity span{
  font-size: 18px;
}
.activity p{
  font-size: 14px;
  line-height: 2;
  letter-spacing: 2px;
  color: #888;
}
.en .activity p{
  letter-spacing: 0;
  margin-top: 9px;
}


.news ul{
  display: flex;
  //justify-content: space-between;
  flex-wrap:wrap
}

.news ul li{
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-left: 5%;
  box-sizing: border-box;
  min-height: 332px;
  background: #ffffff;
  padding-bottom: 20px;
}

.news ul li:hover{
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.3);
  transform: translate3d(0,-5px,0);
  transition: all .2s linear;
}
.title-container{
  padding: 0 30px;
}
.news ul li:nth-child(3n+1){
  margin-left: 0;
}
@media (max-width: 680px) {
  .activity-container{
    width: 100%;
    background: #F5F5F5;
  }
  .activity{
    width: 100%;
    padding: 20px 0;
    margin: 0 auto;
  }

  .activity h4{
    font-size: 28px;
    color:rgb(34, 195, 173);
    font-weight: bold;
    padding-bottom: 10px;
    text-align: center;
  }
  .activity img{
    display: block;
    width:100%;
    min-height: 180px;
    background: #EEEEEE;
    height: auto;
    border-radius: 3px;
  }
  .activity span{
    display: block;
    padding-top: 20px;
    color: #353535;
    font-size: 14px;

    text-align: left;
  }
  .en .activity span{
    font-size: 14px;
  }
  .activity p{
    font-size: 14px;
    letter-spacing: 2px;
    color: #888;
    text-align: left;
  }
  .en .activity p{
    margin-top: 0;
  }

  .news{
    margin-top: 30px;
  }
  .news ul{
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap
  }

  .news ul li{
    border-radius: 10px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width:96%;
    margin-left: 2%;
    height: auto;
    min-height: 100px;
    padding-bottom: 20px;
  }
  .news ul li:nth-child(3n+1){
    margin-left: 2%;
  }

}

</style>
